import store from "@songfinch/customer/store";
import axios from "axios";

const Home = () => import("@songfinch/customer/views/pages/Home");
const ArtistCommunity = () => import("@songfinch/customer/views/pages/ArtistCommunity");
const SongRevisions = () => import("@songfinch/customer/views/pages/SongRevisions");
const PrivacyPolicy = () => import("@songfinch/customer/views/pages/PrivacyPolicy");
const Terms = () => import("@songfinch/customer/views/templates/Terms");
const EventReminder = () => import("@songfinch/customer/views/pages/EventReminder");
const Careers = () => import("@songfinch/customer/views/pages/Careers");
const ContactUs = () => import("@songfinch/customer/views/pages/ContactUs");
// const WorryFree = () => import("@songfinch/customer/views/pages/WorryFree");
const HowItWorks = () => import("@songfinch/customer/views/pages/HowItWorks");
const ExpiredOneTimeLink = () => import("@songfinch/customer/views/pages/ExpiredOneTimeLink");

export default [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/artist-community",
        name: "ArtistCommunity",
        component: ArtistCommunity,
    },
    {
        path: "/song-revisions",
        name: "SongRevisions",
        component: SongRevisions,
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
    },
    {
        path: "/terms",
        name: "Terms",
        component: Terms,
    },
    {
        path: "/event-reminder",
        name: "EventReminder",
        component: EventReminder,
    },
    {
        path: "/careers",
        name: "Careers",
        component: Careers,
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/how-it-works",
        name: "HowItWorks",
        component: HowItWorks,
    },
    // {
    //     path: "/worry-free-guarantee",
    //     name: "WorryFree",
    //     component: WorryFree,
    // },
    {
        path: "/one-time-login",
        name: "OneTimeLogin",
        component: ExpiredOneTimeLink,
        beforeEnter: async (to) => {
            const {token, sign_in_origin} = to.query;

            try {
                // Redeem access token via API
                await axios.get("access_tokens", {
                    params: {token},
                });
                // Fetch logged-in user data
                await store.dispatch("auth/getUserData");

                // Handle redirect based on sign-in origin
                if (sign_in_origin === "checkout") {
                    return {name: "Checkout"};
                }
                return {name: "OrderHistory"};
            } catch (e) {
                // Fall back to expired link page in case of error
            }
        },
    },
];
