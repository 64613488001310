<template>
    <SlideUpDown :model-value="showTopBanner" :duration="duration">
        <component
            :is="url ? 'a' : 'div'"
            :href="url"
            class="@text-sfc-white @text-center @py-4 l5 topBar"
            :class="bannerColor"
            data-top-banner
        >
            <component :is="globalTopBannerPayload.component" v-if="isComponent"/>
            <div v-else class="@block @text-sfc-white @container">
                <div
                    v-if="globalTopBannerPayload?.text && !activeDatedMessage?.text"
                    v-html="globalTopBannerPayload?.text"
                />

                <div v-if="activeDatedMessage?.text">
                    <component
                        :is="component.name"
                        v-for="(component, i) in components"
                        :key="i"
                        v-bind="component.props"
                    />
                </div>
            </div>
        </component>
    </SlideUpDown>
</template>

<script lang="ts">
    import CountdownTimer from "@songfinch/customer/components/shared/CountdownTimer.vue";
    import type {TopBannerDatedItem} from "@songfinch/customer/types/top_banner";
    import {defineComponent} from "vue";
    import useTopBanner from "@songfinch/customer/composables/useTopBanner";
    import dayjs from "dayjs";
    import utc from "dayjs/plugin/utc";
    import timezone from "dayjs/plugin/timezone";
    import isBetween from "dayjs/plugin/isBetween";
    import SlideUpDown from "@songfinch/shared/components/SlideUpDown.vue";
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isBetween);
    export default defineComponent({
        name: "TopBanner",
        components: {CountdownTimer, SlideUpDown},
        setup() {
            const {globalTopBannerPayload, setTopBanner} = useTopBanner();
            return {globalTopBannerPayload, setTopBanner};
        },
        data() {
            return {
                activeDatedMessage: null as TopBannerDatedItem | null,
                timer: null as ReturnType<typeof setInterval>,
                counter: null as Date,
                duration: 1
            };
        },
        computed: {
            isComponent() {
                return typeof this.globalTopBannerPayload?.component === "object";
            },
            showTopBanner() {
                return this.isComponent || this.globalTopBannerPayload?.text || !!this.activeDatedMessage;
            },
            bannerColor() {
                return (this.globalTopBannerPayload?.color || "@bg-sfc-black");
            },
            url() {
                if (this.activeDatedMessage) {
                    return this.activeDatedMessage.url || null;
                }
                if (this.globalTopBannerPayload) {
                    return this.globalTopBannerPayload.url || null;
                }
                return null;
            },
            components() {
                return (this.activeDatedMessage?.text || "").split(/(\[.*?])/).map((part) => {
                    if (part.startsWith("[") && part.endsWith("]")) {
                        const shortcode = part.slice(1, -1);
                        if (shortcode === "CountdownTimer") {
                            return {
                                name: "CountdownTimer",
                                props: {
                                    key: this.counter,
                                    dateTime: this.activeDatedMessage?.endDate
                                }
                            };
                        }
                    }
                    return {
                        name: "div",
                        props: {innerHTML: part}
                    };
                });
            }
        },
        watch: {
            $route(val) {
                const skipTopBanner = val.matched?.find(r => r.meta.hideTopBar);
                const value = skipTopBanner ? undefined : window.appSettings.top_banner;
                this.setTopBanner(value);
            },
            globalTopBannerPayload() {
                this.activeDatedMessage = null;
                if (this.globalTopBannerPayload?.list?.length) {
                    this.getactiveCountDown();
                    this.timer = setInterval(this.getactiveCountDown, 60000);
                } else {
                    clearInterval(this.timer);
                }
            }
        },
        pageLoaded() {
            this.duration = 500;
        },
        methods: {
            getactiveCountDown() {
                if (!this.globalTopBannerPayload?.list) return;
                const now = dayjs.tz(new Date, "America/Chicago");
                this.activeDatedMessage = this.globalTopBannerPayload.list.find((message: TopBannerDatedItem) => {
                    const startDate = dayjs.tz(message.startDate, "America/Chicago");
                    const endDate = dayjs.tz(message.endDate, "America/Chicago");
                    return now.isBetween(startDate, endDate);
                });
                this.counter = new Date();
            }
        }
    });
</script>

<style scoped>
.topBar {
    display: flex;
    justify-content: center;
    align-items: center;

    :deep(p) {
        margin-bottom: 0;
    }
}
</style>
