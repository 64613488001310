<template>
    <div v-if="showToolTip" ref="learnTooltip" class="learnTooltipWrapper" :class="{activeBackDrop}">
        <ToolTip
            :model-value="showToolTip"
            :hide-on-click-outside="false"
            :class="toolClass"
            :position="position"
            :float-style="true"
            :bg-color="bgColor"
            :text-color="textColor"
            width="410"
            :use-smooth-corners="useSmoothCorners"
            @click.stop
        >
            <div v-if="message" v-html="message"/>
            <slot v-else/>

            <slot v-if="$slots.button" name="button" :confirm="confirm"/>
            <div v-else class="@mt-1 !@font-bold l8">
                <a href="#" class="noHover" @click.prevent="confirm" v-text="$t('story.tooltipLearn.button_label')"/>
            </div>
        </ToolTip>
    </div>
</template>

<script>
    import ToolTip from "@songfinch/shared/components/ToolTip";
    import useLearnTooltip from "@songfinch/customer/composables/useLearnTooltip";

    export default {
        name: "LearnTooltip",
        components: {ToolTip},
        props: {
            message: {
                type: String,
                default: ""
            },
            flagName: {
                type: String,
                required: true
            },
            toolClass: {
                type: String,
                default: ""
            },
            position: {
                type: String,
                default: "bottom"
            },
            saveInLocalStorage: {
                type: Boolean,
                default: true,
            },
            eventDelay: {
                type: Number,
                default: 300
            },
            useSmoothCorners: {
                type: [Boolean, Object],
                default: false
            },
            order: {
                type: Array,
                default: null,
            },
            bgColor: {
                type: String,
                default: "var(--sfc-blue)"
            },
            textColor: {
                type: String,
                default: "var(--sfc-white)"
            },
            activeBackDrop: {
                type: Boolean,
                default: false,
            }
        },
        emits: ["confirm"],
        setup() {
            const {learnTooltipsFlags, setLearnTooltipFlag} = useLearnTooltip();
            return {learnTooltipsFlags, setLearnTooltipFlag};
        },
        computed: {
            showToolTip() {
                if (!this.order) return !!this.learnTooltipsFlags[this.flagName];
                for (const name of this.order) {
                    if (this.learnTooltipsFlags[name] && name !== this.flagName) return false;
                    if (this.learnTooltipsFlags[name]) return true;
                }
                return false;
            }
        },
        mounted() {
            if (this.saveInLocalStorage) {
                this.setLearnTooltipFlag(this.flagName, !this.$flags.get(this.flagName));
            }
        },
        methods: {
            confirm() {
                if (this.saveInLocalStorage) this.$flags.set(this.flagName, true);
                this.setLearnTooltipFlag(this.flagName, false);
                setTimeout(() => this.$emit("confirm"), this.eventDelay);
            }
        }
    };
</script>

<style scoped>
    .learnTooltipWrapper.activeBackDrop {
        &:before {
            content: "";
            position: fixed;
            left: 0;
            top: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(16, 16, 14, 0.15);;
        }
    }
    :deep(.toolTip) {
        .toolTipContent {
            padding: 20px 22px 30px;
        }
    }
</style>
