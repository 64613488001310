import {reactive} from "vue";

type existingFlags = "songbuilder_progress" | "ag_navigation"

interface FlagsList {
    [flag: string | existingFlags]: boolean
}

const learnTooltipsFlags: FlagsList = reactive({});

const setLearnTooltipFlag = (key: string | existingFlags, value: boolean) => learnTooltipsFlags[key] = value;

export default function useLearnTooltip() {
    return {
        setLearnTooltipFlag,
        learnTooltipsFlags,
    };
}
