import store from "../store";
const ArtistSingle = () => import("@songfinch/customer/views/artists/ArtistSingle");
const ArtistsList = () => import("@songfinch/customer/views/artists/ArtistsList");
const OpportunityConfirmation = () => import("@songfinch/customer/views/artists/OpportunityConfirmation");
const ArtistInfoPopup = () => import("@songfinch/customer/components/song_builder/ArtistInfoPopup");


export default [
    {
        path: "/artists",
        name: "ArtistsList",
        component: ArtistsList,
    },
    {
        path: "/artists/:id",
        name: "ArtistSingle",
        meta: {CMSData: "artistSingle", customPageViewedAnalyticsTrigger: true},
        component: ArtistSingle,
        beforeEnter: async (to, from) => {
            if (["BsSongArtistsList", "BsStoryArtistsList"].includes(from.name) && !to.query.close_songbuilder) {
                const component = (await ArtistInfoPopup()).default;
                store.commit("shared/showSlideModal", {
                    component,
                    props: {
                        artistId: to.params.id
                    },
                    swalSettings: {
                        width: 500,
                        customClass: {
                            container: "@p-0 slide noPadding tablet-mobile:@pt-[120px] tablet-mobile:@px-0",
                            popup: "desktop:@min-h-screen tablet-mobile:@rounded-t-[5%]",
                            htmlContainer: "@m-0",
                            closeButton: "color-white"
                        },
                    }
                });
                return false;
            }
        }
    },
    {
        path: "/opportunity-confirmation",
        name: "OpportunityConfirmation",
        component: OpportunityConfirmation,
    }
];
