<template>
    <div class="mobileNav">
        <div class="shadow" @click.stop="show = false"/>
        <div class="gz-burger-button" @click="show = !show">
            <span/><span/><span/>
        </div>
        <div class="gz-menu" :class="{right}">
            <div v-if="closeIcon" class="gz-close-icon" @click="show = false">
                <span/>
                <span/>
            </div>
            <div class="gz-item-list" @click="clickOnMenu">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    import {useStyleTag} from "@vueuse/core";

    export default {
        name: "MobileNav",
        props: {
            right: Boolean,
            closeIcon: Boolean
        },
        data() {
            return {
                show: false,
                songPlayingWhenNavShown: false,
            };
        },
        watch: {
            show(val) {
                document.body.classList.toggle("openedMenuBg", val);
                //If we are showing the menu, stop playback and store flag that we were playing
                //Else check and resume playback if we were playing before opening the menu
                val ? this.stopPlaybackOnOpeningMenu() : this.resumePlaybackOnClosingMenuIfNecessary();
            }
        },
        created() {
            let str = "";
            for (let i = 1; i < 21; i++) {
                str += `.mobileNav .gz-menu .gz-item-list > a:nth-child(${i}) {transition-delay: calc(${i} * 0.05s)}`;
            }
            useStyleTag(str);
        },
        methods: {
            clickOnMenu(e) {
                if (e.target.closest("a, button")) {
                    this.show = false;
                }
            },
            resumePlaybackOnClosingMenuIfNecessary() {
                if (this.songPlayingWhenNavShown && !this.$sfPlayer.isPlaying) {
                    //Clear our tracking flag, will be set next time we show nav
                    this.songPlayingWhenNavShown = false;
                    //resume playback
                    this.$sfPlayer.play();
                }
            },
            stopPlaybackOnOpeningMenu() {
                if (this.$sfPlayer.isPlaying) {
                    this.songPlayingWhenNavShown = true;
                    this.$sfPlayer.pause();
                }
            }
        }
    };
</script>

<style>
    .mobileNav {
        .shadow {
            position: fixed;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            opacity: 0;
            z-index: var(--zix-nav-mobile-shadow);
            transition: opacity 0.3s;
            background-color: var(--sfc-black);
        }

        .gz-burger-button {
            position: relative;
            width: 20px;
            height: 14px;
            cursor: pointer;

            span {
                background-color: var(--sfc-black);
                position: absolute;
                height: 2px;
                left: 0;
                right: 0;

                &:nth-child(2) {
                    top: 50% !important;
                    transform: translateY(-50%);
                }

                &:nth-child(3) {
                    top: auto !important;
                    bottom: 0;
                }
            }
        }

        .gz-menu {
            height: 100%; /* 100% Full-height */
            width: 0; /* 0 width - change this with JavaScript */
            position: fixed; /* Stay in place */
            z-index: var(--zix-nav-mobile-menu); /* Stay on top */
            top: 0;
            left: 0;
            min-width: 0;
            background-color: var(--sfc-black);
            overflow-x: hidden; /* Disable horizontal scroll */
            transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-items: center;
            transition: 0.5s;

            .gz-item-list {
                margin-left: 10%;
                font-size: 20px;
                width: 100%;
                max-height: 100%;
                padding: 20px 0;
                overflow-y: auto;

                @screen mobile {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 40px;

                    > a:first-child {
                        margin-top: auto;
                    }
                }

                > * {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    text-decoration: none;
                    padding: 0.7em;
                }

                > a {
                    color: var(--sfc-white);
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    padding: 10px 0;
                    text-align: center;
                    min-width: 200px;
                    transform: translateX(-50%) scale(0);
                    transition: all 0.3s;
                    flex-wrap: wrap;

                    &.h2 {
                        margin-bottom: 0;
                    }
                }
            }

            .border-bottom {
                opacity: 0.15;
            }

            .gz-close-icon {
                position: absolute;
                top: 20px !important;
                right: 10px !important;
                cursor: pointer;
                height: 24px;
                width: 24px;

                span {
                    background: var(--sfc-medium-grey);
                    width: 2px;
                    height: 18px;
                    position: absolute;
                    transform: rotate(-45deg);

                    &:nth-child(2) {
                        transform: rotate(45deg)
                    }
                }
            }

            &.right {
                left: auto;
                right: 0;

                .gz-item-list {
                    > a {
                        transform: translateX(50%) scale(0);
                    }
                }
            }
        }
    }

    .openedMenuBg {
        /* Menu active*/
        background: inherit !important;
        overflow-y: hidden;

        .shadow {
            width: 100%;
            height: 100%;
            opacity: 0.3;
        }

        .gz-menu {
            min-width: 100%;
            height: 100%;
            overflow-y: auto;

            @screen tablet {
                min-width: calc(100% - 100px);
            }

            .gz-item-list {
                margin-left: 0;

                > a {
                    transform: translateX(0) scale(1) !important;
                }
            }
        }
    }
</style>
