<template>
    <div
        id="app"
        v-cta-handler
        class="bluredApp"
        :class="[appClass, $route.matched[0]?.meta.animation, getAdditionalAppClass]"
        :style="{visibility}"
    >
        <TopBanner/>
        <transition :duration="animationDuration">
            <StartSongButton v-show="showStartButton" hide-none-active class="bsContinueLink l5"/>
        </transition>
        <transition :duration="animationDuration">
            <navigation v-show="showNavigation"/>
        </transition>
        <AudioPlayer/>
        <div id="mainContent">
            <router-view v-slot="{Component}">
                <transition :name="animationName" :duration="animationDuration">
                    <component :is="Component" :key="transitionKey"/>
                </transition>
            </router-view>
        </div>
        <transition :duration="animationDuration">
            <FooterCustomer v-show="showFooter" class="@mt-auto"/>
        </transition>
        <Modal @modal-clicked="clickEventHandler"/>
    </div>
    <component :is="'style'" v-text="store.state.cms.pageData?._custom_css"/>
</template>

<script lang="ts" setup>
    import {ref, computed, watch} from "vue";
    import {useRoute} from "vue-router";
    import {useStore} from "vuex";


    import FooterCustomer from "./components/shared/FooterCustomer.vue";
    import Navigation from "./components/shared/Navigation.vue";
    import TopBanner from "./components/shared/TopBanner.vue";
    import Modal from "@songfinch/shared/plugins/modal/Modal.vue";
    import StartSongButton from "@songfinch/customer/components/global/StartSongButton.vue";

    import vCtaHandler, {clickEventHandler} from "@songfinch/customer/directives/cta_handler_directive";
    import {checkUrlQueryForAdIds} from "./helpers/query_ad_id_handler";

    import {getAdditionalAppClass} from "@songfinch/shared/composables/useAdditionalAppClass";
    import {eventBus} from "@songfinch/shared/plugins/event_bus";

    document.body.setAttribute("data-theme", "customer");

    const route = useRoute();
    const store = useStore();

    const appClass = ref(route.matched?.[0]?.name);
    const animationName = ref("");
    const animationDuration = ref(1);
    const visibility = ref("hidden");
    const previousRouteAnimation = ref("");

    const showNavigation = computed(() => !route.matched?.find(r => r.meta.hideNavgiation));
    const showFooter = computed(() => !route.matched?.find(r => r.meta.hideFooter));
    const showStartButton = computed(() => !route.matched?.find(r => r.meta.hideStartButton));
    const transitionKey = computed(() => route.matched[1] ? route.matched[0].name : route.path);

    watch(() => route.name, (newVal, oldValue) => {
        appClass.value = route.matched?.[0]?.name;
        const prevAnimation = previousRouteAnimation.value;
        previousRouteAnimation.value = route.matched[0]?.meta.animation;
        if (!oldValue) return;
        if (route.matched[0]?.meta.animation === "pageSlide") {
            animationName.value = "slideInBuildSong";
            animationDuration.value = 1000;
        } else if (prevAnimation  === "pageSlide") {
            animationName.value = "slideOutBuildSong";
            animationDuration.value = 1000;
        } else {
            animationName.value = "custFade";
            animationDuration.value = 500;
        }
    });

    function pageLoaded() {
        checkUrlQueryForAdIds(route.query);
        const welcomScreen = document.getElementById("welcomeScreen");
        welcomScreen?.remove();
        visibility.value = "visible";
        eventBus.off("pageLoaded", pageLoaded);
    }
    //We want manually unassign here because redirect could be triggered
    eventBus.on("pageLoaded", pageLoaded, false);
</script>

<style>
    .test {
        /*Leave it here!*/
    }

    .slideInBuildSong-enter-active, .slideInBuildSong-leave-active {
        transition: all 0.5s ease;
    }

    .slideInBuildSong-enter-from {
        height: 0 !important;
    }

    .slideOutBuildSong-enter-active, .slideOutBuildSong-leave-active {
        transition: all 0.5s ease;
    }

    .slideOutBuildSong-leave-to {
        height: 0 !important;
    }
</style>

<style scoped>
    #app {
        background-color: var(--sfc-beige);
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        transition: background-color 0.5s linear 0.5s;

        #mainContent {
            flex-grow: 1;
            z-index: 2;
            overflow-x: hidden;
            transition: z-index 0.5s step-end;
            display: flex;
            flex-direction: column;
            position: relative;
        }

        &.pageSlide {
            width: 100%;
            overflow: hidden;

            header {
                background-color: transparent;
                position: static;
            }

            footer {
                max-height: 0;
            }

            #mainContent {
                z-index: var(--zix-main-content-song-builder);
                position: relative;
                transition: z-index 0s step-start;
            }

            .mkAudioPlayerBox {
                z-index: var(--zix-audio-player-box-song-builder);
            }
        }
    }

    .bsContinueLink {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--sfc-black);
        color: var(--sfc-white);
        margin-bottom: 0;
        text-align: center;
        padding: 22px 20px;
        z-index: var(--zix-continue-song-builder);
        transition: all 0.3s;

        &:hover {
            color: var(--sfc-red);
        }
    }
</style>
