const Playlists = () => import("@songfinch/customer/views/playlist/Playlists");
const PlaylistSingle = () => import("@songfinch/customer/views/playlist/PlaylistSingle");

export default [
    {
        path: "/playlists",
        name: "Playlists",
        component: Playlists,
    },
    {
        path: "/playlists/:id",
        name: "PlaylistSingle",
        meta: {customPageViewedAnalyticsTrigger: true},
        component: PlaylistSingle,
    }
];
