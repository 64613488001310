import axios, {type AxiosError} from "axios";
import {computed, readonly, ref} from "vue";
import {useSfIDBKeyval} from "@songfinch/customer/composables/useSfIDBKeyval";
import {useStorage, StorageSerializers} from "@vueuse/core";
import store from "@songfinch/customer/store";
import router from "@songfinch/customer/router";
import get_error from "@songfinch/shared/helpers/get_error";
import {nth, pick} from "lodash-es";
import {loadProductOrDefault} from "@songfinch/customer/composables/useProduct";
import {$toastMsg} from "@songfinch/shared/plugins/toast_msg";
import {InstantProductAnswersType} from "@songfinch/data-source/stories/stories";
import type {IpCollectionCmsData} from "@songfinch/customer/types/instant/IpCmsData";
import {getCmsData} from "@songfinch/customer/helpers/instantStepsHelpers";
import type {InstantProductAnswersObject, InstantSubmissionData, ProductInstantData, PreviewTaskID, PreviewListItem, CustomAxiosRequestConfig} from "@songfinch/customer/types/instant/InstantBuilder";
import type {InstantProductCollectionData} from "@songfinch/customer/types/instant/IpCollectionsData";
import useInstantNames, {resetNamesData} from "@songfinch/customer/composables/useInstantNames";

const {selectedName, selectedNameKey} = useInstantNames();

const DEFAULT_INSTANT_PRODUCT = "instant-product";
const selectedInstantProduct = useStorage<string>("sf_selected_instant_product", DEFAULT_INSTANT_PRODUCT, undefined, {writeDefaults: false});

export function setSelectedInstantProduct(slug: string) {
    selectedInstantProduct.value = slug;
}
//########################
//Collection
const vitamixCollectionId = useStorage<number>("sf_instant_vitamix_collection_id", null, undefined, {serializer: StorageSerializers.number});
const vitamixCollectionTitle = useStorage<string>("sf_instant_vitamix_collection_title", null);

export async function setVitamixCollection(val: string | number) {
    val = parseInt(val as string);
    if (!val || (vitamixCollectionId.value && vitamixCollectionId.value !== val)) {
        resetInstantData();
    }
    vitamixCollectionId.value = val || null;
    await Promise.all([
        setVitamixCollectionData(val),
        loadInstantCollection()
    ]);
    selectedInstantProduct.value = collectionData.value.product_slug || DEFAULT_INSTANT_PRODUCT;
}

//Title used from CMS that why we saving int separately
async function setVitamixCollectionData(val: number, categories = null, prefix = "") {
    if (!val) {
        vitamixCollectionTitle.value = null;
        return;
    }

    if (!categories) {
        let data = getCmsData<IpCollectionCmsData>("IpCollections");
        if (!data.categories) {
            data = await store.dispatch("cms/getField", {group: "instantBuilder", slug: "IpCollections"});
        }
        categories = data.categories;
    }

    categories?.find(item => {
        if (+item.cat_id === val) {
            vitamixCollectionTitle.value = prefix + item.title;
            return true;
        }
        if (item.subcategories && setVitamixCollectionData(val, item.subcategories, `${item.title}: `)) return true;
    });
}

const collectionData = ref<InstantProductCollectionData>();

export async function loadInstantCollection() {
    const res = await vitamixApiClient.get(`/collections/${vitamixCollectionId.value}/questions`);
    collectionData.value = {id: vitamixCollectionId.value, ...res.data} as InstantProductCollectionData[];
}

//########################
//Vitamix Api
export const vitamixApiClient = axios.create({
    baseURL: window.location.origin + "/vitamix/api/v1/",
    headers: {
        "Accept": "application/json",
        "X-CSRF-Token": (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content
    }
});

vitamixApiClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        const config = error.config as CustomAxiosRequestConfig;
        if (!config?.skipToast) $toastMsg(get_error(error));
        if (error?.response?.data?.reset_data) {
            resetInstantData();
            router.push({name: "IpCollections"}).then();
        }
        return Promise.reject(error);
    }
);
//########################

//########################
// Answers
const answersStorage = useSfIDBKeyval<InstantProductAnswersObject>("sf_instant_answers", {});
export function setAnswer(key: string, value?: string) {
    if (value) {
        answersStorage.data.value[key] = value;
    } else {
        delete answersStorage.data.value[key];
    }
}

const answersUpdated = ref(false);

export function setAnswersUpdated(val: boolean) {
    answersUpdated.value = val;
}

//########################
// Preview


const currentPreviewIndexStorage = useSfIDBKeyval<number>("sf_current_selected_preview", -1);
const previewsListStorage = useSfIDBKeyval<PreviewListItem[]>("sf_instant_previews_list", []);
const previewTaskID = useStorage<PreviewTaskID>("sf_instant_preview_task_id", "");

export const isIDBDataLoaded = () => Promise.all([
    currentPreviewIndexStorage.isReady(),
    previewsListStorage.isReady(),
    answersStorage.isReady(),
]);

export function setCurrentSelectedPreview(val: number) {
    currentPreviewIndexStorage.data.value = val;
}

function throwAndRedirectToStep(message, index) {
    throw ({error: message, redirectObj: {name: "IpBuilderStep", params: {step: +index + 1}}});
}

async function getAllAnswers(data: InstantProductAnswersObject) {
    const answers = {...data};
    if (!collectionData.value?.questions.length) await loadInstantCollection();

    collectionData.value.questions.forEach((step, index) => {
        // If answer key without value exist, check if we have GENERAL key in asnwers list and max answer allowed ansers 2 or more
        // Then set unasnwered key with GENERAL
        const hasGeneral = step.line_keys.length > 1 && step.answers.find(answer => answer.answer_key === "GENERAL");
        step.line_keys.forEach(q => {
            if (!answers[q]) {
                if (!hasGeneral) throwAndRedirectToStep("Missing selection", index);
                answers[q] = "GENERAL";
            } else {
                const answerExistInList = step.answers.some(answer => answer.answer_key === answers[q]);
                if (!answerExistInList) {
                    setAnswer(q, undefined); // remove not existing answer
                    setAnswersUpdated(true);
                    throwAndRedirectToStep("Unable to find answer", index);
                }
            }
        });
    });

    return answers;
}

export async function submitPreview(previewIndex?: number) {
    previewIndex ??= currentPreviewIndexStorage.data.value;
    let answers;
    try {
        answers = await getAllAnswers(answersStorage.data.value);
    } catch (e) {
        $toastMsg(get_error(e));
        if (e?.redirectObj) return router.push(e.redirectObj);
        return router.push({name: "InstantProductBuilder"});
    }
    const data: InstantSubmissionData = {
        answers,
        is_preview: "true",
        name_key: selectedNameKey.value,
        spelling: selectedName.value?.[0].customSpelling
    };

    if (previewIndex === -1) {
        data.exclude_artists = previewsListStorage.data.value.map(p => p.artist_id);
    } else {
        data.artist_id = previewsListStorage.data.value[previewIndex].artist_id;
    }
    const res = await vitamixApiClient.post(`/collections/${vitamixCollectionId.value}/previews`, data);
    previewTaskID.value = res.data.task_id;

    return router.push({name: "IpLoadingPreview"});
}

type GetTaskDataParams = {
    taskId?: string,
    skipToast?: boolean
}

export async function getTaskData({taskId, skipToast}: GetTaskDataParams = {}) {
    taskId ||= previewTaskID.value;
    if (!taskId) return;
    const res = await vitamixApiClient.get(`/collections/${vitamixCollectionId.value}/previews/${taskId}`, {skipToast});
    const data = res.data;

    if (data.status === "completed") {
        data.data.ip_builder_data  = {
            answers: {...answersStorage.data.value},
            name_key: selectedNameKey.value,
            spelling: selectedName.value?.[0].customSpelling
        };
        data.data.preview_count = 0;
        if (currentPreviewIndexStorage.data.value === -1) {
            const nextPreview = previewsListStorage.data.value.length;
            previewsListStorage.data.value.push(data.data);
            setCurrentSelectedPreview(nextPreview);
        } else {
            previewsListStorage.data.value[currentPreviewIndexStorage.data.value] = data.data;
        }

        data.progress = 100;
        previewTaskID.value = null;
    }
    return data;
}

export async function lockSong(previewSong: PreviewListItem) {
    const name = selectedInstantProduct.value;
    const product = await loadProductOrDefault({productSlug: name}, DEFAULT_INSTANT_PRODUCT);
    if (selectedInstantProduct.value !== product.name) selectedInstantProduct.value = product.name;

    let answers;
    let task_id;
    try {
        answers = await getAllAnswers(answersStorage.data.value);
        task_id = await submitFullSong(previewSong, answers);
    } catch (e) {
        store.commit("shared/warningMsg", get_error(e));
        return router.push({name: "InstantProductBuilder"});
    }

    // Not sure which collection do we need
    product.instant_data = {
        collection_id: vitamixCollectionId.value,
        artist_id: previewSong.artist_id,
        artist_display_name: previewSong.artist,
        task_id: task_id,
        title: previewSong.title,
        lyrics: previewSong.lyrics,
        template_song_id: previewSong.template_song_id,
        answers,
        previews_generated: previewsListStorage.data.value.map(preview => pick(preview, ["title", "artist"]))
    } as ProductInstantData;

    const cartIndex = store.state.cart.cart.findProductIndexByName(name);
    if (cartIndex !== -1) store.commit("cart/resetCart");

    if (!await store.dispatch("cart/addProduct", {product, skipToast: true, dontShowDetailModal: true})) return false;
    await router.push({name: "IpConfirm"});
}

async function submitFullSong(previewSong: PreviewListItem, answers: InstantProductAnswersType) {
    const data: InstantSubmissionData = {
        answers,
        template_song_id: previewSong.template_song_id,
        is_preview: "false",
        name_key: selectedNameKey.value,
        spelling: selectedName.value?.[0].customSpelling
    };
    const res = await vitamixApiClient.post(`/collections/${vitamixCollectionId.value}/previews`, data);
    return res.data.task_id;
}

export function resetInstantData() {
    previewsListStorage.data.value = [];
    answersStorage.data.value = {};
    currentPreviewIndexStorage.data.value = -1;
    lastStepVisited.value = "";
    previewTaskID.value = null;
    vitamixCollectionId.value = null;
    vitamixCollectionTitle.value = "";
    selectedInstantProduct.value = DEFAULT_INSTANT_PRODUCT;
    resetNamesData();
}
//##########################################
// Instant bundler page

const lastStepVisited = useStorage<string>("sf_instant_last_step_v2", "");
export function setLastPageVisited(fullPath: string) {
    lastStepVisited.value = fullPath;
}

//##########################################
const exportData = {
    collectionData: readonly(collectionData),
    answers: readonly(answersStorage.data),
    vitamixCollectionId: readonly(vitamixCollectionId),
    vitamixCollectionTitle: readonly(vitamixCollectionTitle),
    previewsList: readonly(previewsListStorage.data),
    currentPreviewIndex: readonly(currentPreviewIndexStorage.data),
    currentPreview: readonly(computed<PreviewListItem>(() => nth(previewsListStorage.data.value, currentPreviewIndexStorage.data.value) as PreviewListItem)),
    answersUpdated: readonly(answersUpdated),
    selectedInstantProduct: readonly(selectedInstantProduct),
    lastStepVisited: readonly(lastStepVisited)
};

export default function useInstantProductBuilder() {
    return exportData;
}
