<template>
    <span class="countdownTimer">
        {{formatTime}}
    </span>
</template>

<script lang="ts">
    import {defineComponent} from "vue";
    import dayjs from "dayjs";
    import utc from "dayjs/plugin/utc";
    import timezone from "dayjs/plugin/timezone";
    import duration from "dayjs/plugin/duration";

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(duration);
    export default defineComponent({
        name: "CountdownTimer",
        props: {
            dateTime: {
                type: [String, Number],
                required: true
            }
        },
        computed: {
            formatTime() {
                const now = dayjs.tz(new Date(), "America/Chicago");
                const endTime = dayjs.tz(this.dateTime, "America/Chicago");
                const diff = endTime.diff(now, "seconds");
                const timeLeft = Math.max(0, diff);

                const duration = dayjs.duration({seconds: timeLeft});
                const days = Math.floor(duration.asDays());
                const hours = Math.floor(duration.asHours()) % 24;
                const minutes = Math.floor(duration.asMinutes()) % 60;
                let formattedTime = "";
                if (days > 0) {
                    formattedTime += `${days} day${days > 1 ? "s" : ""} : `;
                }
                if (hours > 0) {
                    formattedTime += `${(hours)} HRS : `;
                }
                return formattedTime += `${minutes} MIN left`;
            }
        }
    });
</script>

<style>
    .countdownTimer {
        color: #FF9678;
    }
</style>
